export const AllCategories = [
  {
    name: "All",
    id: "Menu",
  },
  {
    name: "Veggies",
    id: "Veggies",
  },
  {
    name: "Grains",
    id: "Grains",
  },
];
